import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { MessageDetailPage } from '../pages/notifications/MessageDetailPage'
import { MessagesPage } from '../pages/notifications/MessagesPage'

const MailBoxRoutes = () => (
  <Routes>
    <Route path="/notificaciones" element={<MessagesPage />} />
    <Route path="/notificaciones/:noti_id" element={<MessageDetailPage />} />
    <Route path="*" element={<Navigate to="/notificaciones" replace />} />
  </Routes>
)

export default MailBoxRoutes
